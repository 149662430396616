@tailwind base;
@tailwind components;
@tailwind utilities;

.filepond--credits {
  color: white!important;
}

.sticky-header th {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1;
}

